import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import {
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
} from 'react-share'
import { makeStyles } from '@material-ui/core/styles'
import { BLOG_SITE_META } from '../utils/constants/site'

const DEFAULT_SIZE = 22

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
  button: {
    '&:focus': {
      outline: 'none',
      textDecoration: 'none',
    },
  },
}))

const withDefaultSocialIconProps = (
  size,
  IconComponent,
  ButtonComponent,
  buttonProps
) => (
  <Box mx={0.5}>
    <ButtonComponent {...buttonProps}>
      <IconComponent size={size} round={true} />
    </ButtonComponent>
  </Box>
)

export default function SocialMediaShare({ shareMetaData, ...props }) {
  const classes = useStyles()

  return (
    <Box {...props}>
      {withDefaultSocialIconProps(
        DEFAULT_SIZE,
        FacebookIcon,
        FacebookShareButton,
        {
          className: classes.button,
          url: shareMetaData.source,
          quote: shareMetaData.title,
        }
      )}
      {withDefaultSocialIconProps(
        DEFAULT_SIZE,
        LinkedinIcon,
        LinkedinShareButton,
        {
          className: classes.button,
          url: shareMetaData.source,
          title: shareMetaData.title,
          summary: shareMetaData.description,
          source: shareMetaData.source,
        }
      )}
      {withDefaultSocialIconProps(DEFAULT_SIZE, RedditIcon, RedditShareButton, {
        className: classes.button,
        url: shareMetaData.source,
        title: shareMetaData.title,
      })}
      {withDefaultSocialIconProps(
        DEFAULT_SIZE,
        TwitterIcon,
        TwitterShareButton,
        {
          className: classes.button,
          title: shareMetaData.title,
          url: shareMetaData.source,
          hashtags: shareMetaData.hashtags,
        }
      )}
    </Box>
  )
}

SocialMediaShare.propTypes = {
  /**
   * Information to provide to the social media share events.
   */
  shareMetaData: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    source: PropTypes.string,
    hashTags: PropTypes.arrayOf(PropTypes.string),
  }),
  /**
   * Any props for the Box system component
   */
  props: PropTypes.object,
}

SocialMediaShare.defaultProps = {
  shareMetaData: BLOG_SITE_META,
  props: {},
}
