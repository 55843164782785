import React from 'react'
import PropTypes from 'prop-types'
import {
  List,
  ListItem,
  ListItemText,
  Link,
} from '@material-ui/core'

export default function AttachmentsList(props) {
  return (
    <List>
      {props.attachments.map((attachment) => {
        return (
          <ListItem>
            <ListItemText
              primary={
                <Link target="_blank" rel="noopener noreferrer" href={attachment.publicURL}>{attachment.name}</Link>
              }
            />
          </ListItem>
        )
      })}
    </List>
  )
}

AttachmentsList.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      publicURL: PropTypes.string,
    })
  ),
}
