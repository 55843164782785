import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { BLOG_COMPONENT_OVERRIDES } from '../layouts/MDXLayout'
import MDXLayout from '../layouts/MDXLayout'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { PROD_SITE_URL } from '../../utils/constants/site'
import PagesLayout from '../layouts/PagesLayout'
import React from 'react'
import Seo from '../Seo'
import SocialMediaShare from '../SocialMediaShare'
import TableOfContents from '../blog/TableOfContents'
import TagList from '../blog/TagList'
import AttachmentsList from '../blog/Attachments'
import { graphql } from 'gatsby'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: -30,
    padding: theme.spacing(4),
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0, 0, 0),
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(0),
    },
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    maxWidth: 800,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      overFlowX: 'auto',
      padding: 0,
      margin: 0,
    },
  },
  blogTitle: {
    fontFamily: 'PT Serif, serif',
    color: theme.palette.primary.dark,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 1.2,
    marginBottom: theme.spacing(4),
  },
  shortDescription: {
    color: grey[500],
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Source Sans Pro, sans-serif',
  },
  iframe: {
    border: 0,
    left: 0,
    top: 0,
    width: 370,
    height: 400,
  },
  tags: {
    marginLeft: theme.spacing(2),
  },
}))

const Post = ({ data: { mdx: post } }) => {
  const { frontmatter, body, timeToRead, fields, tableOfContents } = post
  const classes = useStyles()
  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <PagesLayout>
      <Seo
        title={frontmatter.title}
        description={frontmatter.shortDescription}
      />
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.tags}>
            <TagList tags={frontmatter.tags} />
          </div>
          <Grid container justifyContent="flex-start">
            <Grid item xs={12} md={10}>
              <Card elevation={0} className={classes.card}>
                <CardHeader
                  title={
                    <Typography
                      variant="h3"
                      component="h1"
                      className={classes.blogTitle}
                    >
                      {frontmatter.title}
                    </Typography>
                  }
                  subheader={
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      flexDirection={isLargeScreen ? 'row' : 'column'}
                    >
                      <Typography color="textSecondary">
                        {`${frontmatter.updatedAt}${timeToRead !== null ? ` • ${timeToRead} min read` : ''
                          }`}
                      </Typography>
                      <SocialMediaShare
                        display="flex"
                        flexDirection="row"
                        shareMetaData={{
                          title: frontmatter.title,
                          description: frontmatter.shortDescription,
                          source: `${PROD_SITE_URL}${fields.slug}`,
                          hashTags: frontmatter.tags.map(({ label }) => label),
                        }}
                      />
                    </Box>
                  }
                />
                <CardContent className={classes.content}>
                  {frontmatter.shortDescription && (
                    <Box mb={4}>
                      <Typography
                        className={classes.shortDescription}
                        variant="h5"
                        component="h2"
                        gutterBottom
                      >
                        {frontmatter.shortDescription}
                      </Typography>
                    </Box>
                  )}
                  <MDXLayout shortCodes={BLOG_COMPONENT_OVERRIDES}>
                    <MDXRenderer>{body}</MDXRenderer>
                  </MDXLayout>
                </CardContent>
              </Card>
              {frontmatter.showNewsLetter && (
                <iframe
                  title="Subscribe to my newsletter"
                  className={classes.iframe}
                  src="https://cdn.forms-content.sg-form.com/f4b0cf4f-9ebf-11ea-bde8-b643acc5373f"
                />
              )}
            </Grid>
            {frontmatter.attachments?.length > 0 ? (
              <AttachmentsList
                attachments={frontmatter.attachments}
              />
            ) : null}
            {tableOfContents?.items?.length > 0 ? (
              <Hidden smDown>
                <Grid item md={2}>
                  <TableOfContents contents={tableOfContents.items} />
                </Grid>
              </Hidden>
            ) : null}
          </Grid>
        </div>
      </div>
    </PagesLayout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        shortDescription
        isVisible
        updatedAt(formatString: "MMMM DD, YYYY")
        createdAt(formatString: "MMMM DD, YYYY")
        showNewsLetter
        tags
        attachments {
          publicURL
          name
        }
      }
      tableOfContents(maxDepth: 2)
    }
  }
`

export default Post
