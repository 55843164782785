/**
 * Thank you to the the folks at Material-UI for making this table of contents
 * This is from: https://github.com/mui-org/material-ui/blob/master/docs/src/modules/components/AppTableOfContents.js
 */

import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  Fragment,
  useCallback,
} from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    // Fix IE 11 position sticky issue.
    marginTop: 70,
    top: 70,
    flexShrink: 0,
    position: 'sticky',
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    paddingBottom: theme.spacing(2),
  },
  contents: {
    paddingLeft: theme.spacing(1),
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  item: {
    fontSize: '.8125rem',
    padding: theme.spacing(
      0.5,
      0,
      0.5,
      `${Math.max(0, theme.spacing(1) - 3)}px`
    ),
    borderLeft: `3px solid transparent`,
    boxSizing: 'border-box',
    '&:hover': {
      borderLeftColor: theme.palette.grey[200],
    },
    '&$active,&:active': {
      borderLeftColor: theme.palette.grey[300],
    },
  },
  secondaryItem: {
    paddingLeft: theme.spacing(2),
  },
  active: {
    padding: theme.spacing(0.5, 2),
    display: 'inline-block',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 4,
    color: 'white',
  },
}))

function getItemsClient(headings) {
  const itemsWithNode = []

  headings.forEach((item) => {
    const hash = item.url.substring(1)
    itemsWithNode.push({
      ...item,
      node: document.getElementById(hash),
    })

    if (item.items?.length > 0) {
      item.items.forEach((subitem) => {
        itemsWithNode.push({
          ...subitem,
          node: document.getElementById(hash),
        })
      })
    }
  })
  return itemsWithNode
}

const noop = () => {}

function useThrottledOnScroll(callback, delay) {
  const throttledCallback = useMemo(
    () => (callback ? throttle(callback, delay) : noop),
    [callback, delay]
  )

  useEffect(() => {
    if (throttledCallback === noop) {
      return undefined
    }

    window.addEventListener('scroll', throttledCallback)
    return () => {
      window.removeEventListener('scroll', throttledCallback)
      throttledCallback.cancel()
    }
  }, [throttledCallback])
}

export default function TableOfContents({ contents }) {
  const classes = useStyles()
  const itemsWithNodeRef = useRef(getItemsClient(contents))
  const clickedRef = useRef(false)
  const unsetClickedRef = useRef(null)
  const [activeState, setActiveState] = useState(null)

  const findActiveIndex = useCallback(() => {
    // Don't set the active index based on scroll if a link was just clicked
    if (clickedRef.current) {
      return
    }

    let active
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 70) {
        active = { url: null }
        break
      }

      const item = itemsWithNodeRef.current[i]

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 8
      ) {
        active = item
        break
      }
    }

    if (active && activeState !== active.url) {
      setActiveState(active.url)
    }
  }, [activeState])

  //   Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(contents.length > 0 ? findActiveIndex : null, 166)

  const handleClick = (hash) => (event) => {
    // Ignore click for new tab/new window behavior
    if (
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return
    }

    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false
    }, 1000)

    if (activeState !== hash) {
      setActiveState(hash)
    }
  }

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current)
    },
    []
  )

  const ItemLink = ({ item, secondary }) => (
    <div className={classes.item}>
      <Link
        display="block"
        color={activeState === item.url ? 'textPrimary' : 'textSecondary'}
        href={item.url}
        underline="none"
        onClick={handleClick(item.url)}
        className={clsx(
          { [classes.secondaryItem]: secondary },
          activeState === item.url ? classes.active : undefined
        )}
      >
        {item.title}
      </Link>
    </div>
  )

  return (
    <nav className={classes.root} aria-label="table of contents">
      {contents.length > 0 ? (
        <Fragment>
          <Typography gutterBottom className={classes.contents}>
            Contents
          </Typography>
          <Typography component="ul" className={classes.ul}>
            {contents.map((item) => (
              <li key={item.title}>
                <ItemLink item={item} />
                {item.items?.length > 0 ? (
                  <ul className={classes.ul}>
                    {item.items?.map((subitem) => (
                      <li key={subitem.title}>
                        <ItemLink item={subitem} secondary />
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))}
          </Typography>
        </Fragment>
      ) : null}
    </nav>
  )
}

TableOfContents.propTypes = {
  /**
   * Links and title for the table of content.
   */
  contents: PropTypes.array.isRequired,
}
